@import "main.css";
.navbar-nav > li > span, h1, h2, .photoTitleText, .first-activity-title, .second-activity-title, .third-activity-title, .nav-link { color :#721031 !important;
}

.photoTitleText > h1 { font-size: 2.4rem!important
}
h2{ font-size: 2.2rem!important
}
.link-services > div > div > span > p { color :#721031 !important;
}
.animation{display : none !important;
}
.picto_card > div {margin-bottom : 0rem!important;
}